import React, { useState } from "react";
import html2canvas from "html2canvas";
import Layout from "../Layout";
import Swal from "sweetalert2";
import { states } from "../../../data";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import "./styles.css";
import Paginate from "../../Sections/Paginate/index";
import myImg from "../../../../src/assets/images/finallogo.jpeg";
import Loader from "../Loader";
const StudentRegisterPageSection = () => {
  const [getAllCenterData, setGetAllCenterData] = useState([]);
  const [getAllCourseData, setGetAllCourseData] = useState([]);
  const [getAllMarksheetData, setGetAllMarksheetCourseData] = useState([]);
  const [getAllMarksData, setGetAllMarksData] = useState([]);
  const [showMarksData, setShowMarksData] = useState([]);
  const [marksData, setMarksData] = useState([]);
  const [icardData, setIcardData] = useState([]);
  const [studentId, setStudentId] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pagenumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(5);

  const [studentData, setStudentData] = useState({
    MarksheetTypeID: "",
    StudentCourseId: "",
    StudentFirstName: "",
    StudentLastName: "",
    StudentEmail: "",
    StudentMobile: "",
    StudentDob: "",
    StudentPicture: "",
    StudentFatherName: "",
    StudentMotherName: "",
    StudentSessionStart: "",
    StudentSessionEnd: "",
  });

  const handleGetCourseData = async () => {
    try {
      const getCourse = await axios.get("api/admin/course/get-course");
      if (getCourse?.data?.isCourseFound === true) {
        setGetAllCourseData(getCourse?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleGetMarksheetType = async () => {
    try {
      const getMarksheet = await axios.get(
        "api/admin/get-marksheettype-center"
      );

      if (getMarksheet?.data?.ismarksheetTypeFound === true) {
        setGetAllMarksheetCourseData(getMarksheet?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleOnChange = (key, value) => {
    setStudentData((studentData) => ({
      ...studentData,
      [key]: value,
    }));
  };
  const handlePostSubAdmin = async (e) => {
    e.preventDefault();
    try {
      const ajaxMarkData = await axios.post(
        "api/center/register-student",
        studentData
      );
      if (ajaxMarkData?.data?.isStudentRegisterd === true) {
        toast.success(ajaxMarkData?.data?.msg);
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleDelete = () => {
    Swal.fire({
      // title: "Are you sure?",
      text: "Are you sure you won't be deleted?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        alert("delete successfully");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("Cancelled");
      }
    });
  };

  const handleGetCenterData = async (pageN, pageL) => {
    debugger;
    setLoading(true);
    try {
      const getCenter = await axios.get(
        `api/center/center-student/${pageN}/${pageL}`
      );

      if (getCenter?.data?.isStudentFound === true) {
        setGetAllCenterData(getCenter?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };

  const handleGetMarksData = async (id) => {
    try {
      const getMarksData = await axios.get(`api/center/get-subjects/${id}`);

      if (getMarksData?.status === 200) {
        setGetAllMarksData(getMarksData?.data?.data);
        setMarksData(getMarksData?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleShowMarksData = async (id) => {
    try {
      const showMarksData = await axios.get(
        `api/center/check-students-marks/${id}`
      );
      if (showMarksData?.status === 200) {
        setShowMarksData(showMarksData?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
    if (newFile !== undefined) {
      reader.onloadend = async () => {
        setLoading(true);
        try {
          if (newFile.size > 1024 * 1024) {
            alert("Image size should be less then 1 Mb");
          } else {
            const formdata = new FormData();
            formdata.append("Images", newFile);
            const resp = await axios.post(`api/upload/images`, formdata);

            if (resp.status === 200) {
              const uploadedImg = resp.data.image;
              handleOnChange("StudentPicture", uploadedImg);
              // setFirstImageUrl(reader.result);
              toast.success(resp?.data?.message, {});
            } else {
              toast.error("Error", {});
            }
          }
        } catch (error) {
          if (error.response.status === 400) {
            var err_400 = error?.response?.data?.message;
            toast.error(err_400);
          } else if (error.response.status === 401) {
            const err_401 = error?.response?.data?.message;
            toast.error(err_401);
          } else if (error.response.status === 413) {
            const err_413 = "image size should be less then 1 MB";
            toast.error(err_413);
          } else if (error.response.status === 500) {
            const err_500 = error?.response?.data?.message;
            toast.error(err_500);
          } else {
            console.log("Error in kyc details", error);
            toast.error("something went wrong");
          }
        }
        setLoading(false);
      };
      reader.readAsDataURL(newFile);
    }
  };

  const getCouseName = (corseId) => {
    let getcouse = getAllCourseData?.filter((value) => value._id === corseId);
    getcouse = getcouse[0].CourseFullName;

    return getcouse;
  };

  const handleMarksChange = (index, value, stdId) => {
    const updatedMarksData = [...marksData];
    const updatedItem = { ...updatedMarksData[index], Marks: value };
    updatedMarksData[index] = updatedItem;
    setMarksData(updatedMarksData);
  };
  const handleSaveMarks = async () => {
    const myMarksData = {
      StudentId: studentId,
      Subjects: marksData,
    };
    try {
      const ajaxMarkData = await axios.post(
        "api/center/add-marks",
        myMarksData
      );
      if (ajaxMarkData?.status === 200) {
        toast.success(ajaxMarkData?.data?.msg);
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.msg;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.msg;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.msg;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 409) {
        const message_409 = error?.response?.data?.msg;
        console.log("message_409", message_409);
        toast.error(message_409);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.msg;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };
  const handleCountPagination = async () => {
    try {
      const getCountNo = await axios.get("api/center/center-students-count");
      if (getCountNo?.status === 200) {
        let x = getCountNo?.data?.count / pageLimit;
        if (Number.isInteger(x)) {
          setPageCount(x);
        } else {
          x = x + 1;
          x = parseInt(x);
          setPageCount(x);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageClick = async (event) => {
    setPageNumber(event.selected + 1);
    handleGetCenterData(event.selected + 1, pageLimit);
  };

  useEffect(() => {
    handleGetCenterData(pagenumber, pageLimit);
    handleGetCourseData();
    handleGetMarksheetType();
    handleCountPagination();
  }, []);
  return (
    <Layout>
      {loading && <Loader loading={loading} />}
      <form className="d-flex mb-3 my-3">
        <div className="row w-100 justify-content-between g-3">
          <div className="col-lg-5 col-md-6 col-sm-12">
            <div className="input-group ">
              <input
                type="text"
                className="form-control"
                placeholder="Search Address"
                aria-label="Search"
                aria-describedby="button-addon2"
              />
              <button
                className="button-styles"
                type="button"
                id="button-addon2"
              >
                <i class="fa-solid fa-magnifying-glass"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
      <button
        type="button"
        className="button-styles my-3"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        <i class="fa-solid fa-plus mx-2"></i>
        Create Student
      </button>
      <div className="table-responsive">
        <div>
          <div className="table-responsive table-box">
            <table className="table table-bordered">
              <thead>
                <tr className="table-head">
                  {/* <th>No</th> */}
                  {/* <th>Student CenterId</th> */}
                  <th>Student CourseName</th>
                  <th>Student Picture</th>
                  <th>Student FirstName</th>
                  <th>Student astName</th>
                  <th>Student Email</th>
                  <th>Student Dob</th>
                  <th>Student FatherName</th>
                  <th>Student MotherName</th>
                  <th>Student Mobile</th>
                  <th>Student SessionStart</th>
                  <th>Student SessionEnd</th>
                  <th>Created Date</th>
                  <th>Add Marks</th>
                  <th>Show Marks</th>
                  <th>ICard</th>
                </tr>
              </thead>
              <tbody>
                {getAllCenterData.map((nft, index) => (
                  <tr key={index}>
                    {/* <td>{index + 1}</td> */}
                    <td>{nft.CourseName}</td>
                    {/* <td>{getCouseName(nft?.StudentCourseId)}</td> */}
                    <td>
                      <div className="tbl-img">
                        <img
                          src={`https://backend.nileshdawande.in/images/${nft?.StudentPicture}`}
                          alt="img3"
                        />
                      </div>
                    </td>
                    <td>{nft.StudentFirstName}</td>
                    <td>{nft.StudentLastName}</td>
                    <td>{nft.StudentEmail}</td>
                    <td>{nft.StudentDob}</td>
                    <td>{nft.StudentFatherName}</td>
                    <td>{nft.StudentMotherName}</td>
                    <td>{nft.StudentMobile}</td>
                    <td>{nft.StudentSessionStart}</td>
                    <td>{nft.StudentSessionEnd}</td>
                    <td>{nft.createdAt}</td>
                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#editsubadmin"
                        disabled={nft.IsAddMarks ? false : true}
                        onClick={() => (
                          handleGetMarksData(nft?._id), setStudentId(nft?._id)
                        )}
                      >
                        <i class="fa-solid fa-pen-to-square mx-2"></i>
                        Add Marks
                      </button>
                    </td>

                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#showmarks"
                        disabled={nft.IsAddMarks ? true : false}
                        onClick={() => handleShowMarksData(nft?._id)}
                      >
                        <i class="fa-solid fa-pen-to-square mx-2"></i>
                        Show Marks
                      </button>
                    </td>
                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#icard"
                        onClick={() => setIcardData(nft)}
                      >
                        <i class="fa-solid fa-pen-to-square mx-2"></i>
                        ICard
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* pagenation  */}
      <div className="container">
        <Paginate pageCount={pageCount} handlePageClick={handlePageClick} />
      </div>

      {/* Add modal form */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Create Student
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handlePostSubAdmin(e)}>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Student Course
                  </label>
                  <select
                    className="form-control"
                    onChange={(e) =>
                      handleOnChange("StudentCourseId", e.target.value)
                    }
                  >
                    <option value="" defaultChecked>
                      ---Select---
                    </option>
                    {getAllCourseData?.map((value, key) => {
                      return (
                        <option value={value?._id}>
                          {value?.CourseShortName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Marksheet Type
                  </label>

                  <select
                    className="form-control"
                    onChange={(e) =>
                      handleOnChange("MarksheetTypeID", e.target.value)
                    }
                  >
                    <option value="" defaultChecked>
                      ---Select---
                    </option>
                    {getAllMarksheetData?.map((value, key) => {
                      return (
                        <option value={value?._id}>{value?.shortName}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Student FirstName
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={studentData.StudentFirstName}
                    onChange={(e) =>
                      handleOnChange("StudentFirstName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Student LastName
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={studentData.StudentLastName}
                    onChange={(e) =>
                      handleOnChange("StudentLastName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Student Mobile No
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={studentData.StudentMobile}
                    onChange={(e) =>
                      handleOnChange("StudentMobile", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Student Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    value={studentData.StudentEmail}
                    onChange={(e) =>
                      handleOnChange("StudentEmail", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Student Date of Birth
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={studentData.StudentDob}
                    onChange={(e) =>
                      handleOnChange("StudentDob", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Student Picture
                  </label>
                  <input
                    type="file"
                    accept="image/jpg, image/jpeg, image/png"
                    onChange={(e) => handleImageChange(e)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Student Father Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={studentData.StudentFatherName}
                    onChange={(e) =>
                      handleOnChange("StudentFatherName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Student Mother Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={studentData.StudentMotherName}
                    onChange={(e) =>
                      handleOnChange("StudentMotherName", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Student Session Start
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={studentData.StudentSessionStart}
                    onChange={(e) =>
                      handleOnChange("StudentSessionStart", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Student Session End
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={studentData.StudentSessionEnd}
                    onChange={(e) =>
                      handleOnChange("StudentSessionEnd", e.target.value)
                    }
                  />
                </div>
                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-thumbs-up mx-2"></i>Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* SetMarks modal form */}
      <div
        className="modal fade"
        id="editsubadmin"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Marks
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div>
                {getAllMarksData?.map((value, key) => {
                  return (
                    <div className="row">
                      <div className="col-12">
                        <div className="row my-2">
                          <div className="col-8">
                            <label
                              htmlFor="recipient-name"
                              className="col-form-label fw-bold"
                            >
                              Subject Full Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={value?.SubjectFullName}
                              disabled
                            />
                          </div>
                          <div className="col-4">
                            <label
                              htmlFor="recipient-name"
                              className="col-form-label fw-bold"
                            >
                              Set Marks
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              // value={value?.Marks}
                              onChange={(e) =>
                                handleMarksChange(key, e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <button
                  className="button-styles my-4"
                  onClick={handleSaveMarks}
                >
                  <i class="fa-solid fa-pen-nib mx-2"></i>Save Marks
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* GetMarks modal form */}
      <div
        className="modal fade"
        id="showmarks"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Show Marks
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div>
                {showMarksData?.map((value, key) => {
                  return (
                    <div className="row">
                      <div className="col-12">
                        <div className="row my-2">
                          <div className="col-8">
                            <label
                              htmlFor="recipient-name"
                              className="col-form-label fw-bold"
                            >
                              Subject Full Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={value?.SubjectFullName}
                              disabled
                            />
                          </div>
                          <div className="col-4">
                            <label
                              htmlFor="recipient-name"
                              className="col-form-label fw-bold"
                            >
                              Set Marks
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={value?.Marks ? value?.Marks : ""}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* GetMarks modal form */}

      <div
        className="modal fade"
        id="icard"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Show Marks
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>

            <div className="modal-body">
              <div className="main-cus">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="main-card">
                        <div className="logo-div">
                          <img src={myImg} width={70} alt="img" />
                        </div>
                        <div className="text-center">
                          <p className="nfssx">NFSSC</p>
                          <p className="full-form">
                            National Fire & Safety Service College
                          </p>
                          <p className="state">NAGPUR-MAHARASHTRA-INDIA</p>
                          <p className="gov">Govt.Regd.No :F12412 (MH)</p>
                          <div className="idcard-text">
                            <p className="bold">ID CARD</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="icard-details">
                        <div className="user-pic">
                          <img
                            src={`https://backend.nileshdawande.in/images/${icardData?.StudentPicture}`}
                            alt="img"
                          />
                          {/* <h6 className="my-1">Principal Signature</h6> */}
                        </div>
                        <div className="idcard-cus-table">
                          <table class="table">
                            <tbody>
                              <tr>
                                <td className="fw-bold">Name</td>:
                                <td>
                                  {icardData?.StudentFirstName}{" "}
                                  {icardData?.StudentLastName}
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-bold">Father Name</td>:
                                <td>{icardData?.StudentFatherName}</td>
                              </tr>

                              <tr>
                                <td className="fw-bold">Date Of Birth</td>:
                                <td>{icardData?.StudentDob}</td>
                              </tr>
                              <tr>
                                <td className="fw-bold">Mob</td>:
                                <td>{icardData?.StudentMobile}</td>
                              </tr>
                              {/* <tr>
                                <td className="fw-bold">Program</td>:
                                <td>MCA *</td>
                              </tr> */}
                              {/* <tr>
                                <td>Center</td>:<td>Mumbai</td>
                              </tr> */}
                              <tr>
                                <td className="fw-bold">Session</td>:
                                <td>{icardData?.StudentSessionStart}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer position="top-right" />
    </Layout>
  );
};
export default StudentRegisterPageSection;
