import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./component/Pages/LoginPage/index";
import DashboardPage from "./component/Pages/DashboardPage";
import axios from "axios";
import StudentRegisterPage from "./component/Pages/StudentRegisterPage";
import PaymentRequiestPage from "./component/Pages/PaymentRequiestPage";
import NotificationPage from "./component/Pages/NotificationPage";
function App() {
  (function () {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  })();
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/student-register" element={<StudentRegisterPage />} />
        <Route path="/payment-requiest" element={<PaymentRequiestPage />} />
        <Route path="/notification" element={<NotificationPage />} />
      </Routes>
    </>
  );
}
export default App;
