import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/images/finallogo.jpeg";

const Sidebar = ({ togglesideBar, toggle }) => {
  const location = useLocation();
  const sideLinks = [
    {
      menu: "Dashboard",
      redirect: "dashboard",
      icon: "fa-solid fa-gauge",
    },
    {
      menu: "Student Register",
      redirect: "student-register",
      icon: "fa-solid fa-print",
    },
    {
      menu: "PaymentRequiest",
      redirect: "payment-requiest",
      icon: "fa-solid fa-share",
    },
    {
      menu: "Notification",
      redirect: "notification",
      icon: "fa-solid fa-layer-group",
    },
  ];
  const isActive = (path) => {
    return location.pathname === path;
  };
  return (
    <div>
      <div className={`sidebar ${toggle && "show"}`}>
        <div className="toggle-btn close" onClick={togglesideBar}>
          <i class="fa-solid fa-xmark"></i>
        </div>
        <a href="/" className="logo">
          <img src={logo} alt="logo" className="w-75" />
          <span className="fs-4"> SubAdmin Panel</span>
        </a>
        <hr />
        <ul className="nav nav-pills flex-column mb-auto">
          {sideLinks.map((data, index) => {
            return (
              <li key={index}>
                <Link
                  to={`/${data?.redirect}`}
                  activeClassName="active"
                  className={`nav-link link-body-emphasis ${
                    isActive(`/${data?.redirect}`) ? "active" : ""
                  }`}
                >
                  <i className={`${data?.icon} mx-2`}></i>
                  {data.menu}
                </Link>
              </li>
            );
          })}
        </ul>
        <hr />
      </div>
    </div>
  );
};

export default Sidebar;
