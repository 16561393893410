import React, { useState } from "react";
import Layout from "../Layout";
import Swal from "sweetalert2";
import { states } from "../../../data";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import "./styles.css";
const PaymentRequiestSection = () => {
  const [getAllPaymentData, setgetAllPaymentData] = useState([]);
  const [getAdminData, setGetAdminData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paymentData, setpaymentData] = useState({
    PaymentAmount: "",
    PaymentScreenShot: "",
    PaymentRefNumber: "",
  });

  const handleOnChange = (key, value) => {
    setpaymentData((paymentData) => ({
      ...paymentData,
      [key]: value,
    }));
  };
  const handlePostPayment = async (e) => {
    e.preventDefault();
    try {
      const ajaxPayment = await axios.post(
        "api/center/create-payment-request",
        paymentData
      );
      if (ajaxPayment?.data?.isPaymentCreated === true) {
        toast.success(ajaxPayment?.data?.msg);
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleDelete = () => {
    Swal.fire({
      // title: "Are you sure?",
      text: "Are you sure you won't be deleted?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        alert("delete successfully");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("Cancelled");
      }
    });
  };

  const handleGetAllPaymentData = async () => {
    try {
      const getPayment = await axios.get("api/center/get-payment-request");
      if (getPayment?.status === 200) {
        setgetAllPaymentData(getPayment.data.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleGetQrcode = async () => {
    try {
      const getAdminPaymentDetails = await axios.get(
        "api/admin/get-center-payment"
      );
      console.log("getAdminPaymentDetails", getAdminPaymentDetails);
      if (getAdminPaymentDetails?.data?.isPaymentFound === true) {
        setGetAdminData(getAdminPaymentDetails.data.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
    if (newFile !== undefined) {
      reader.onloadend = async () => {
        setLoading(true);
        try {
          if (newFile.size > 1024 * 1024) {
            alert("Image size should be less then 1 Mb");
          } else {
            const formdata = new FormData();
            formdata.append("Images", newFile);
            const resp = await axios.post(`api/upload/images`, formdata);
            console.log("resp");
            if (resp.status === 200) {
              console.log("res", resp);
              const uploadedImg = resp.data.image;
              handleOnChange("PaymentScreenShot", uploadedImg);
              toast.success(resp?.data?.message, {});
            } else {
              toast.error("Error", {});
            }
          }
        } catch (error) {
          if (error.response.status === 400) {
            var err_400 = error?.response?.data?.message;
            toast.error(err_400);
          } else if (error.response.status === 401) {
            const err_401 = error?.response?.data?.message;
            toast.error(err_401);
          } else if (error.response.status === 413) {
            const err_413 = "image size should be less then 1 MB";
            toast.error(err_413);
          } else if (error.response.status === 500) {
            const err_500 = error?.response?.data?.message;
            toast.error(err_500);
          } else {
            console.log("Error in kyc details", error);
            toast.error("something went wrong");
          }
        }
        setLoading(false);
      };
      reader.readAsDataURL(newFile);
    }
  };

  useEffect(() => {
    handleGetAllPaymentData();
    handleGetQrcode();
  }, []);

  return (
    <Layout>
      <form className="d-flex mb-3 my-3">
        <div className="row w-100 justify-content-between g-3">
          <div className="col-lg-5 col-md-6 col-sm-12">
            <div className="input-group ">
              <input
                type="text"
                className="form-control"
                placeholder="Search Address"
                aria-label="Search"
                aria-describedby="button-addon2"
              />
              <button
                className="button-styles"
                type="button"
                id="button-addon2"
              >
                <i class="fa-solid fa-magnifying-glass"></i>
              </button>
            </div>
          </div>
        </div>
      </form>

      <div className="row">
        {getAdminData.map((value, key) => {
          return (
            <>
              <div className="col-12 col-md-3">
                <div className="card cus-card">
                  <img
                    src={`https://backend.nileshdawande.in/images/${value?.PaymentPhoto}`}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h6 className="fw-bold">Acc Name : {value?.AccountName}</h6>
                    <h6 className="fw-bold">
                      Acc Number : {value?.AccountNumber}
                    </h6>
                    <h6 className="fw-bold">BankName : {value?.BankName}</h6>
                    <h6 className="fw-bold">IfscCode : {value?.IfscCode}</h6>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
      <button
        type="button"
        className="button-styles my-3"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        <i class="fa-solid fa-plus mx-2"></i>
        Payment
      </button>
      <div className="table-responsive">
        <div>
          <div className="table-responsive table-box">
            <table className="table table-bordered">
              <thead>
                <tr className="table-head">
                  <th>Payment No</th>
                  <th>CenterId</th>
                  <th>PaymentAmount</th>
                  <th>PaymentRefNumber</th>
                  <th>Screen Short</th>
                  <th>Status</th>
                  <th>Created Date</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {getAllPaymentData.map((nft, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{nft.CenterId}</td>
                    <td>{nft.PaymentAmount}</td>
                    <td>{nft.PaymentRefNumber}</td>
                    <td>
                      <div className="tbl-img">
                        <img
                          src={`https://backend.nileshdawande.in/images/${nft?.PaymentScreenShot}`}
                          alt="img3"
                        />
                      </div>
                    </td>
                    <td
                      className={`fw-bold ${
                        nft.Status === 0
                          ? "text-warning "
                          : nft.Status === 1
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {nft.Status === 0
                        ? "Pending"
                        : nft.Status === 1
                        ? "Active"
                        : "Reject"}
                    </td>
                    <td>{nft.createdAt}</td>
                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#editsubadmin"
                      >
                        <i class="fa-solid fa-pen-to-square mx-2"></i>
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                        className="button-styles"
                        type="button"
                        // onClick={() => handleDelete()}
                      >
                        <i class="fa-solid fa-trash mx-2"></i>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Add modal form */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Payment
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handlePostPayment(e)}>
                <div className="mb-3">
                  <label className="col-form-label">Payment Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    value={paymentData.PaymentAmount}
                    onChange={(e) =>
                      handleOnChange("PaymentAmount", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label className="col-form-label">Payment Ref Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={paymentData.PaymentRefNumber}
                    onChange={(e) =>
                      handleOnChange("PaymentRefNumber", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Payment ScreenShot
                  </label>
                  <input
                    type="file"
                    accept="image/jpg, image/jpeg, image/png"
                    onChange={(e) => handleImageChange(e)}
                  />
                </div>
                <button className="button-styles" type="submit">
                  <i class="fa-solid fa-thumbs-up mx-2"></i>Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Edit modal form */}
      <div
        className="modal fade"
        id="editsubadmin"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Sub Admin
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    SubAdmin Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="recipient-name"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="recipient-name"
                  />
                </div>
                <button className="button-styles">
                  <i class="fa-solid fa-pen-nib mx-2"></i>Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </Layout>
  );
};
export default PaymentRequiestSection;
